import '../scripts/integrations/jquery-extended';
import '../scripts/integrations/turbo';
import '../scripts/integrations/sweetalert2';
import '../scripts/integrations/balance-text';

import '../scripts/lib-legacy/cookies';
import '../scripts/lib-legacy/yammer/api';
import '../scripts/lib-legacy/utils';
import '../scripts/lib-legacy/browserDetector';
import '../scripts/lib-legacy/mobile/base';
import '../scripts/lib-legacy/Ajaxify';
import '../scripts/lib-legacy/IdpRedirecter';
import '../scripts/lib-legacy/ui/header';
import '../scripts/lib-legacy/ui/passwordToggle';
import '../scripts/lib-legacy/ui/formLoading';
import '../scripts/integrations/legacy-namespaces/transitions';
import '../scripts/lib-legacy/ui/PasswordUtils';
import '../scripts/lib-legacy/integrations';
import '../scripts/lib-legacy/policyPopup';
import '../scripts/lib-legacy/ScheduleDemo';
import '../scripts/lib-legacy/ui/drawer';
import '../scripts/lib-legacy/oldBrowserMessage';

import loadApp from '../scripts/init';

const pages = import.meta.glob([
  '../scripts/pages/home/home.js',
  '../scripts/pages/home/rewards.js',
  '../scripts/pages/home/index.js',
  '../scripts/pages/home/features.js',
  '../scripts/pages/home/extension.js',
  '../scripts/pages/home/sign_up.js',
  '../scripts/pages/home/pricing.js',
  '../scripts/pages/home/distributedWorkforceInfographic.js',
  '../scripts/pages/home/office365.js',
  '../scripts/pages/home/tour.js',
  '../scripts/pages/home/microsoft_teams_landing.js',
  '../scripts/pages/home/slack.js',
  '../scripts/pages/home/videos.js',
  '../scripts/pages/user_sessions/new.js',
  '../scripts/pages/account_chooser/show.js',
  '../scripts/pages/case_study/index.js',
  '../scripts/pages/articles/index.js',
  '../scripts/pages/articles/show.js',
  '../scripts/pages/home/meet_with_us.js'
], { eager: true });

document.addEventListener("turbo:load", function() {
  loadApp(pages);
});

